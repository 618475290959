import { tituloventas, imagenventas, descripcionventas, titulocol1, descripcion1col1, descripcion2col1, detallescol1, titulocol2, descripcion1col2, descripcion2col2, detallescol2, botonventastextofinanciado, botonventasurlfinanciado } from "../Contenido/ServiciosContenido"
export default function VentaVehiculos(){
    return(
        <>
        
        <div class="container-fluid p-0">
            <img src={`${process.env.PUBLIC_URL}/design/img/servicios/ventas/${imagenventas}`} class="img-fluid w-100" alt="Descripción de la imagen"/>
        </div>


        <div className="container-fluid about bg-light py-5">
            <div className="container py-5">
            <div className="section-title mb-5 wow fadeInUp" data-wow-delay="0.2s">
                    <h1 className="display-5 text-info mb-4">{descripcionventas}</h1>
                </div>
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
                        <div className="section-title text-start mb-5">
                            <h1 className="display-6 mb-4">{titulocol1}</h1>
                            <p className="mb-4">
                             {descripcion1col1}
                            </p>
                            <p className="mb-4">{descripcion2col1}
                            </p>
                            <div className="mb-4">
                            {detallescol1.map((item, index) => (
                                <>
                                <p className="text-info"><i className="fa fa-check text-primary me-2"></i> {item.detalle}</p>
                                </>
                            ))}
                            </div>
                        </div>
                        <div className="row g-5 justify-content-center text-center">
                            <div className="">
                                <a href={botonventasurlfinanciado} className="btn btn-primary rounded-pill text-white py-3 px-5">{botonventastextofinanciado}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
                        <div className="section-title text-start mb-5">
                            <h1 className="display-6 mb-4">{titulocol2}</h1>
                            <p className="mb-4">
                             {descripcion1col2}
                            </p>
                            <p className="mb-4">{descripcion2col2}
                            </p>
                            <div className="mb-4">
                            {detallescol2.map((item, index) => (
                                <>
                                <p className="text-info"><i className="fa fa-check text-primary me-2"></i> {item.detalle}</p>
                                </>
                            ))}
                            </div>
                        </div>
                        <div className="row g-5 justify-content-center text-center">
                            <div className="">
                                <a href={botonventasurlfinanciado} className="btn btn-primary rounded-pill text-white py-3 px-5">{botonventastextofinanciado}</a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        </>
    )
}