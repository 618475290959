export const titulocontacto = 'Contáctanos';
export const imagencontacto = '';

export const descripcióncontacto = 'Te compartimos la información para que nos puedas contactar en nuestras diferentes ubicaciones.';

export const botonesslide = [
    {
        'text': 'Ventas',
    },
    {
        'text': 'Repuestos',
    },
    {
        'text': 'Taller',
    },
]

///////////////////////VENTAS
export const tituloventas = 'Contáctanos';
export const descripciónventas = 'La información registrada es para uso unicamente de BryAuto.';
export const botonventas = 'Enviar';
export const accionbotonventas = '';

export const infoventasicono1 = 'fa fa-map-marker-alt';
export const infoventastexto1 = 'Dirección';
export const infoventasdatos1 = [
    {
        'dato1': '10av A 33-83 Zona 11, Colonia Las Charcas, Ciudad de Guatemala',
    },
]

export const infoventasicono2 = 'fa fa-phone-alt';
export const infoventastexto2 = 'Teléfonos';
export const infoventasdatos2 = [
    {
        'dato2': '+502 55153469',
        'url2': 'tel: +50255153469',

    },
]

export const infoventasicono3 = 'fa fa-envelope-open';
export const infoventastexto3 = 'Correos';
export const infoventasdatos3 = [
    {
        'dato3': 'info@bryautogt.com',
        'url3': 'mailto:info@bryautogt.com',
    },
]

export const mapaventas = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15444.302483964511!2d-90.5649233!3d14.5947667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a1f51cb2e4b5%3A0x4d6704a52524a6dd!2sBRYAUTO%20S.A!5e0!3m2!1ses-419!2sgt!4v1721244181449!5m2!1ses-419!2sgt';


///////////////////////REPUESTOS
export const titulorepuestos = 'Contáctanos';
export const descripciónrepuestos = 'La información registrada es para uso unicamente de BryAuto.';
export const botonrepuestos = 'Enviar';
export const accionbotonrepuestos = '';

export const inforepuestosicono1 = 'fa fa-map-marker-alt';
export const inforepuestostexto1 = 'Dirección';
export const inforepuestosdatos1 = [
    {
        'dato1': '10av A 33-83 Zona 11, Colonia Las Charcas.',
    },
    {
        'dato1': 'Calzada San Juan 0-41 Montserrat 1 zona 4 de Mixco.',
    },
    {
        'dato1': 'Calzada San Juan 14-56 colonia Nueva Monserrat zona 3 de Mixco (Frente a Gasolinera PUMA).',
    },
]

export const inforepuestosicono2 = 'fa fa-phone-alt';
export const inforepuestostexto2 = 'Teléfonos';
export const inforepuestosdatos2 = [
    {
        'dato2': '+502 5865 5172',
        'url2': 'tel: +50258655172',
    },
    {
        'dato2': '+502 5482 9191',
        'url2': 'tel: +50254829191',
    },
]

export const inforepuestosicono3 = 'fa fa-envelope-open';
export const inforepuestostexto3 = 'Correos';
export const inforepuestosdatos3 = [
    {
        'dato3': 'info@bryautogt.com',
        'url3': 'mailto:info@bryautogt.com',
    },
]

export const maparepuestos = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15444.302483964511!2d-90.5649233!3d14.5947667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a1f51cb2e4b5%3A0x4d6704a52524a6dd!2sBRYAUTO%20S.A!5e0!3m2!1ses-419!2sgt!4v1721244181449!5m2!1ses-419!2sgt';


///////////////////////TALLER
export const titulotaller = 'Contáctanos';
export const descripcióntaller = 'La información registrada es para uso unicamente de BryAuto.';
export const botontaller = 'Enviar';
export const accionbotontaller = '';

export const infotallericono1 = 'fa fa-map-marker-alt';
export const infotallertexto1 = 'Dirección';
export const infotallerdatos1 = [
    {
        'dato1': '10av A 33-83 Zona 11, Colonia Las Charcas, Ciudad de Guatemala',
    },
]

export const infotallericono2 = 'fa fa-phone-alt';
export const infotallertexto2 = 'Teléfonos';
export const infotallerdatos2 = [
    {
        'dato2': '+502 5865 1771',
        'url2': 'tel: +50258651771',
    },
]

export const infotallericono3 = 'fa fa-envelope-open';
export const infotallertexto3 = 'Correos';
export const infotallerdatos3 = [
    {
        'dato3': 'info@bryautogt.com',
        'url3': 'mailto:info@bryautogt.com',
    },
]

export const mapataller = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15444.302483964511!2d-90.5649233!3d14.5947667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a1f51cb2e4b5%3A0x4d6704a52524a6dd!2sBRYAUTO%20S.A!5e0!3m2!1ses-419!2sgt!4v1721244181449!5m2!1ses-419!2sgt';
