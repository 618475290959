import React, { useState } from 'react';
import { porquetitulo, porquedescripcion, porquecaracteristicas, porqueboton, porquebotonurl } from "../Contenido/InicioContenido"
export default function Porque(){
    const [selectedItem, setSelectedItem] = useState(null);

    const handleShowModal = (item) => {
        setSelectedItem(item);
        const modal = new window.bootstrap.Modal(document.getElementById('imageCarouselModal'));
        modal.show();
    };
    return(
        <>
        <div className="container-fluid feature py-5">
            <div className="container py-5">
                <div className="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
                    
                    <h1 className="display-3 mb-4">{porquetitulo}</h1>
                    
                    <p className="mb-0">{porquedescripcion}</p>
                </div>
                <div className="row g-4 justify-content-center">
                {porquecaracteristicas.map((item, index) => (
                <div 
                    key={index} 
                    className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp cursor-active" 
                    data-wow-delay="0.1s"
                    onClick={() => handleShowModal(item)}
                >
                    <div className="row-cols-1 feature-item p-4">
                        <div className="col-12">
                            <div className="feature-icon mb-4 d-flex justify-content-center">
                                <div className="p-3 d-inline-flex bg-white rounded">
                                    <img 
                                        src={`/design/img/inicio/porque/${item.imagen}`} 
                                        alt="" 
                                        className="me-2 img-fluid rounded" 
                                        style={{ width: '150px', height: '150px' }} 
                                    />
                                </div>
                            </div>
                            <div className="feature-content d-flex flex-column">
                                <h5 className="mb-4 text-center">{item.titulo}</h5>
                                <p className="mb-0">{item.descripcion}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
                    <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.2s">
                        <a href={porquebotonurl} className="btn btn-primary rounded-pill text-white py-3 px-5">{porqueboton}</a>
                    </div>
                </div>
            </div>
             {/* Bootstrap Modal */}
             <div className="modal fade" id="imageCarouselModal" tabIndex="-1" aria-labelledby="imageCarouselModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title " id="imageCarouselModalLabel">
                                {selectedItem?.titulo}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {/* Bootstrap Carousel */}
                            {selectedItem && (
                                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        {selectedItem.imagenes.map((imagen, index) => (
                                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                <img 
                                                    src={`/design/img/inicio/porque/${imagen}`} 
                                                    className="d-block w-100" 
                                                    alt={`Imagen ${index + 1}`} 
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}