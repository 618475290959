export const logo = 'Logob.png';
export const nombre = 'Nombreb.png';
export const descripcion = 'Somos la Importadora de Vehículos #1 en Centroamérica. Trabajamos arduamente para brindarte el mejor servicio. ¡Somos Bryauto! ¡Somos Audaces!';

export const redessociales = [
    {
        'logo': 'fab fa-whatsapp',
        'url': 'https://wa.me/+50255153469',
    },
    {
        'logo': 'fab fa-tiktok',
        'url': 'https://www.tiktok.com/@bryautogt',
    },
    {
        'logo': 'fab fa-instagram',
        'url': 'https://www.instagram.com/bryautogt/',
    },
]

export const titulocol2 = 'Marcas';
export const opccionescol2 = [
    {
        'datcol2': 'Honda',
        'url': `${process.env.PUBLIC_URL}/marca/Honda`,
    },
    {
        'datcol2': 'Mazda',
        'url': `${process.env.PUBLIC_URL}/marca/Mazda`,
    },
    {
        'datcol2': 'Kia',
        'url': `${process.env.PUBLIC_URL}/marca/Kia`,
    },
    {
        'datcol2': 'Toyota',
        'url': `${process.env.PUBLIC_URL}/marca/Toyota`,
    },
    {
        'datcol2': 'Hyundai',
        'url': `${process.env.PUBLIC_URL}/marca/Hyundai`,
    },
    {
        'datcol2': 'Scion',
        'url': `${process.env.PUBLIC_URL}/marca/Scion`,
    },
]

export const titulocol3 = 'Páginas';
export const opccionescol3 = [
    {
        'datcol3': 'Servicios',
        'url': 'servicios',
    },
    {
        'datcol3': 'Acerca de nosotros',
        'url': 'sobrenosotros',
    },
    {
        'datcol3': 'Contáctanos',
        'url': 'contacto',
    },
]

export const titulocol4 = 'Información de Contacto';
export const opccionescol4 = [
    {
        'logo': 'fa fa-map-marker-alt',
        'info': '10av A 33-83 Zona 11, Colonia Las Charcas.',
        'url': 'https://www.google.com/maps?q=14.5946705,-90.5647485',
    },
    {
        'logo': 'fas fa-envelope',
        'info': 'info@bryautogt.com',
        'url': 'mailto:info@bryautogt.com',
    },
    {
        'logo': 'fas fa-phone',
        'info': '+502 5515 3469',
        'url': 'tel: +50255153469',
    },
    {
        'logo': 'fab fa-whatsapp',
        'info': '+502 5515 3469',
        'url': 'https://wa.me/+50255153469',
    },
    {
        'logo': 'fab fa-whatsapp',
        'info': '+502 4220 7431',
        'url': 'https://wa.me/+50242207431',
    }
]


export const urlwhatsapp = 'https://wa.me/+50255153469';
