//INICIO
/////////Carousel
export const carousel = [
    {
        'imagen': 'Imagen Carrousel 1.png',
        'texto1': '',
        'texto2': '',
        'texto3': '',
    },
    {
        'imagen': 'Imagen Carrousel 2.png',
        'texto1': '',
        'texto2': '',
        'texto3': '',
    },
    {
        'imagen': 'Imagen Carrousel 3.png',
        'texto1': '',
        'texto2': '',
        'texto3': '',
    },
    {
        'imagen': 'Imagen Carrousel 4.png',
        'texto1': '',
        'texto2': '',
        'texto3': '',
    },
    {
        'imagen': 'Imagen Carrousel 5.png',
        'texto1': '',
        'texto2': '',
        'texto3': '',
    },
]

/////////MARCAS
export const marcanombre = 'NUESTRAS MARCAS.';
export const marcatitulo = 'En Bryauto trabajamos con las mejores marcas ya que nuestro cliente es nuestra prioridad.';
export const marcadescripcion = '';
export const marcas = [
    {
        'imagen': 'Marca Honda.png',
        'titulo': 'Honda',
        'descripcion': 'Marca de vehículo en venta.',
         'url': `${process.env.PUBLIC_URL}/marca/Honda`, 
    },
    {
        'imagen': 'Marca Hyundai.png',
        'titulo': 'Hyundai',
        'descripcion': 'Marca de vehículo en venta.',
        'url': `${process.env.PUBLIC_URL}/marca/Hyundai`, 
    },
    {
        'imagen': 'Marca Kia.png',
        'titulo': 'Kia',
        'descripcion': 'Marca de vehículo en venta.',
        'url': `${process.env.PUBLIC_URL}/marca/Kia`, 
    },
    {
        'imagen': 'Marca Mazda.png',
        'titulo': 'Mazda',
        'descripcion': 'Marca de vehículo en venta.',
        'url': `${process.env.PUBLIC_URL}/marca/Mazda`, 
    },
    {
        'imagen': 'Marca Toyota.png',
        'titulo': 'Toyota',
        'descripcion': 'Marca de vehículo en venta.',
        'url': `${process.env.PUBLIC_URL}/marca/Toyota`, 
    },
    {
        'imagen': 'Marca Scion.png',
        'titulo': 'Scion',
        'descripcion': 'Marca de vehículo en venta.',
        'url': `${process.env.PUBLIC_URL}/marca/Scion`, 
    },
]


/////////FINANCIAMIENTOS
export const financiamientotitulo = 'Comprar un carro al crédito nunca fue tan fácil.';
export const financiamientodescripcion = '';
export const imagen1 = 'Circulo.png';
export const imagen2 = 'Rectangulo.png';
export const financiamientos = [
    {
        'financiamiento': 'Financiamiento propio',
    },
    {
        'financiamiento': 'Enganches accesibles',
    },
    {
        'financiamiento': 'Hasta 42 meses de plazo',
    },
]
export const textoboton = 'Descubre más';
export const urlboton = 'https://wa.me/+50255153469';




/////////¿Por que?
export const porquetitulo = '¿POR QUÉ BRYAUTO?';
export const porquedescripcion = 'Somos una empresa comprometida en brindar soluciones de calidad a nuestros clientes.';

export const porquecaracteristicas = [
    {
        'titulo': 'CALIDAD BRYAUTO',
        'descripcion': 'Marca líder a nivel nacional en importación.',
        'imagen': 'Calidad.png',
        'imagenes': ['Taller.png', 'Calidad.png', 'Repuestos.png'] // Agregas aquí las imágenes del carrusel
    },
    {
        'titulo': 'REPUESTOS',
        'descripcion': 'Amplio stock para nuestras líneas.',
        'imagen': 'Repuestos.png',
        'imagenes': ['Garantia.png', 'Repuestos.png', 'Respaldo.png'] // Imágenes del carrusel
    },
    {
        'titulo': 'TALLER',
        'descripcion': 'Mecánicos profesionales a precios accesibles.',
        'imagen': 'Taller.png',
        'imagenes': ['Garantia.png', 'Repuestos.png', 'Respaldo.png']// Imágenes del carrusel
    },
    {
        'titulo': 'GARANTÍA',
        'descripcion': 'Garantía de una semana o 1000km en motor y caja.',
        'imagen': 'Garantia.png',
        'imagenes': ['Garantia.png', 'Repuestos.png', 'Respaldo.png'] // Imágenes del carrusel
    },
    {
        'titulo': 'RESPALDO',
        'descripcion': 'Amplio respaldo con nuestros vehículos.',
        'imagen': 'Respaldo.png',
        'imagenes': ['Garantia.png', 'Repuestos.png', 'Respaldo.png'] // Imágenes del carrusel
    }
];


export const porqueboton = 'Contáctanos';
export const porquebotonurl = 'https://wa.me/+50255153469';


/////////Testimonios
export const testimoniotitulo = 'La Experiencia Bryauto';
export const videotestimonios = 'TestimoniosBryauto.mp4';

export const testimonios = [
    {
        image: 'testimonial-img.jpg',
        text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores nemo facilis tempora esse explicabo sed! Dignissimos quia ullam pariatur blanditiis sed voluptatum. Totam aut quidem laudantium tempora. Minima, saepe earum!',
        name: 'Cliente 1',
        vehicle: 'Vehículo cliente 1',
        rating: 5
    },
    {
        image: 'testimonial-img.jpg',
        text: 'Otro testimonio aquí...',
        name: 'Cliente 2',
        vehicle: 'Vehículo cliente 2',
        rating: 4
    },
    // ... más testimonios
];