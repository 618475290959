import { logo, nombre, descripcion, redessociales, titulocol2, opccionescol2, titulocol3, opccionescol3, titulocol4, opccionescol4, urlwhatsapp } from "../Contenido/FooterContenido"

export default function Footer(){
    return(
        <>
        <div className="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <div className="footer-item d-flex flex-column">
                            <h4 className="text-white mb-4">
                            <img src={`/design/img/Generales/${logo}`} alt="BryAuto Logo" classNameName="img-fluid" style={{height: '20%', width: '20%'}} />
                            <img src={`/design/img/Generales/${nombre}`} alt="BryAuto Logo" classNameName="img-fluid" style={{height: '50%', width: '60%'}}/>
                            </h4>
                            <p>{descripcion}
                            </p>
                            <div className="d-flex align-items-center">
                                <i className="fas fa-share fa-2x text-white me-2"></i>
                                {redessociales.map((item, index) => (
                                <a className="btn-square btn btn-primary text-white rounded-circle mx-1" href={item.url}><i className={item.logo}></i></a>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <div className="footer-item d-flex flex-column">
                            <h4 className="mb-4 text-white">{titulocol2}</h4>
                            {opccionescol2.map((item, index) => (
                            <a href={item.url}><i className="fas fa-angle-right me-2"></i> {item.datcol2}</a>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <div className="footer-item d-flex flex-column">
                        <h4 className="mb-4 text-white">{titulocol3}</h4>
                            {opccionescol3.map((item, index) => (
                            <a href={item.url}><i className="fas fa-angle-right me-2"></i> {item.datcol3}</a>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <div className="footer-item d-flex flex-column">
                        <h4 className="mb-4 text-white">{titulocol4}</h4>
                            {opccionescol4.map((item, index) => (
                            <a href={item.url}><i className={`me-2 ${item.logo}`}></i> {item.info}</a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid copyright py-4">
            <div className="container">
                <div className="row g-4 align-items-center">
                    <div className="col-md-6 text-center text-md-start mb-md-0">
                        <span className="text-white"><i className="fas fa-copyright text-light me-2"></i>BryAuto S.A., All right reserved.</span>
                    </div>
                    <div className="col-md-6 text-center text-md-end text-white">
                         Designed By <span className="border-bottom text-info">BFSolutions</span> Distributed By <span className="border-bottom text-info">BFSolutions</span>
                    </div>
                </div>
            </div>
        </div>

       
        <a href={urlwhatsapp} className="btn btn-success btn-lg-square back-to-top"><i className="fab fa-whatsapp fa-lg"></i></a>   

        </>
    )
}